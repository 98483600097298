import { Form, FormControl, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoginButtonIcon from "../../../src/Asset/Images/Icons/LoginButtonIcon.svg";
import LoginKeyIcon from "../../../src/Asset/Images/Icons/LoginKeyIcon.svg";
import LoginUserIcon from "../../../src/Asset/Images/Icons/LoginUserIcon.svg";

function AuthLogin() {
  return (
    <>
      <div className="login-title">
        Sign in to
        <span className="d-block">
          your account <span className="three-dots">...</span>
        </span>
      </div>
      <p className="login-description">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed convallis nibh. Nulla id
        ante quam.
      </p>
      <Form>
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">
            <span>
              <img src={LoginUserIcon} alt="" />
            </span>
          </InputGroup.Text>
          <FormControl
            type="email"
            placeholder="Username"
            aria-label="Username"
            aria-describedby="basic-addon1"
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">
            <span>
              <img src={LoginKeyIcon} alt="" />
              {/* <LoginKeyIcon /> */}
            </span>
          </InputGroup.Text>
          <FormControl
            type="password"
            placeholder="Password"
            aria-label="Password"
            aria-describedby="basic-addon1"
            autoComplete="current-password"
          />
        </InputGroup>
        <Form.Group className="form-button d-flex justify-content-between">
          <Link className="btn btn-primary" to="/home">
            <span className="me-3">
              <img src={LoginButtonIcon} alt="" />
            </span>
            Login
          </Link>
          <Link className="forgot-link" to="/forgotPassword">
            Forgot Password ?
          </Link>
        </Form.Group>
      </Form>
    </>
  );
}

export default AuthLogin;
