import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "react-pro-sidebar/dist/css/styles.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./Asset/Styles/App.scss";
import "./Asset/Styles/Component.scss";
import Loader from "./Component/Loader/Index";
import DefaultLayout from "./Container/DefaultLayout";
import AuthLayout from "./Views/Authentication/AuthLayout";

const UserProfile = React.lazy(() => import("./Views/Common/Profile/Index"));
const ContactPage = React.lazy(() => import("./Views/Contact"));
const Calendar = React.lazy(() => import("./Views/Miscellaneous/Calendar"));
const UploadDocument = React.lazy(() => import("./Views/Miscellaneous/UploadDocument"));
const OpportunityGroup = React.lazy(() => import("./Views/Opportunity/Group"));
const OpportunityLnr = React.lazy(() => import("./Views/Opportunity/Lnr"));
const OpportunityMeetingCatering = React.lazy(() => import("./Views/Opportunity/MeetingCatering"));
const OpportunityRfp = React.lazy(() => import("./Views/Opportunity/Rfp"));
const ReportMain = React.lazy(() => import("./Views/Reports/Index"));
const Activity = React.lazy(() => import("./Views/Activity/Index"));
const HomePage = React.lazy(() => import("./Views/Home"));
const ComponentsPage = React.lazy(() => import("./Views/ComponentsPage"));
const Accounts = React.lazy(() => import("./Views/Accounts/AccountIndex"));
const AccountsActive = React.lazy(() => import("./Views/Accounts/AccountActive"));
const AccountsInactive = React.lazy(() => import("./Views/Accounts/AccountInactive"));
const AccountDetails = React.lazy(() => import("./Views/Accounts/DetailView/Index"));
const SuspectDetails = React.lazy(() => import("./Views/Suspect/DetailView/index"));
const Suspect = React.lazy(() => import("./Views/Suspect/SuspectIndex"));
const NonViableSuspect = React.lazy(() => import("./Views/Suspect/NonViableSuspect"));
const OpenActivitiesSuspect = React.lazy(() => import("./Views/Suspect/OpenActivities"));
const OpenSuspect = React.lazy(() => import("./Views/Suspect/OpenSuspect"));
const SetupAndConfigurations = React.lazy(() => import("./Views/SetupAndConfiguration/Index"));
const CreateNewClients = React.lazy(() => import("./Views/SetupAndConfiguration/CreateNewClients"));
const Portfolios = React.lazy(() => import("./Views/SetupAndConfiguration/Portfolios"));
const Hotels = React.lazy(() => import("./Views/SetupAndConfiguration/Hotels"));
const Users = React.lazy(() => import("./Views/SetupAndConfiguration/UserManagement/Users"));
const UsersRole = React.lazy(
  () => import("./Views/SetupAndConfiguration/UserManagement/UsersRole")
);
const AccountRules = React.lazy(() => import("./Views/SetupAndConfiguration/AccountRules"));
const MarketSagmentTypes = React.lazy(
  () => import("./Views/SetupAndConfiguration/MarketSagmentTypes")
);
const AdditionalSettings = React.lazy(
  () => import("./Views/SetupAndConfiguration/AdditionalSettings")
);
const ClientProperties = React.lazy(() => import("./Views/SetupAndConfiguration/ClientProperties"));
const CompanyProfile = React.lazy(() => import("./Views/SetupAndConfiguration/CompanyProfile"));
const GeneralSettings = React.lazy(() => import("./Views/SetupAndConfiguration/GeneralSettings"));

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<AuthLayout />}></Route>
          <Route path="/forgotPassword" element={<AuthLayout />}></Route>
          <Route path="/" element={<DefaultLayout />}>
            <Route
              path="/home"
              element={
                <React.Suspense fallback={<Loader />}>
                  <HomePage />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/userProfile"
              element={
                <React.Suspense fallback={<Loader />}>
                  <UserProfile />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/changePassword"
              element={
                <React.Suspense fallback={<Loader />}>
                  <UserProfile />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/componentPage"
              element={
                <React.Suspense fallback={<Loader />}>
                  <ComponentsPage />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/account"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Accounts />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/accounts/active"
              element={
                <React.Suspense fallback={<Loader />}>
                  <AccountsActive />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/accounts/details"
              element={
                <React.Suspense fallback={<Loader />}>
                  <AccountDetails />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/accounts/inactive"
              element={
                <React.Suspense fallback={<Loader />}>
                  <AccountsInactive />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/activity"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Activity />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/activity/all"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Activity />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/activity/blitz"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Activity />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/activity/clientVisit"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Activity />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/activity/email"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Activity />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/activity/followUp"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Activity />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/activity/meeting"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Activity />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/activity/networking"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Activity />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/activity/phoneCall"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Activity />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/activity/siteVisit"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Activity />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/activity/teleMarketing"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Activity />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/activity/others"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Activity />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/suspects"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Suspect />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/suspect/open"
              element={
                <React.Suspense fallback={<Loader />}>
                  <OpenSuspect />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/suspect/nonViable"
              element={
                <React.Suspense fallback={<Loader />}>
                  <NonViableSuspect />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/suspect/openActivities"
              element={
                <React.Suspense fallback={<Loader />}>
                  <OpenActivitiesSuspect />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/suspect/details"
              element={
                <React.Suspense fallback={<Loader />}>
                  <SuspectDetails />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/contact"
              element={
                <React.Suspense fallback={<Loader />}>
                  <ContactPage />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/opportunity/rfp"
              element={
                <React.Suspense fallback={<Loader />}>
                  <OpportunityRfp />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/opportunity/lnr"
              element={
                <React.Suspense fallback={<Loader />}>
                  <OpportunityLnr />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/opportunity/group"
              element={
                <React.Suspense fallback={<Loader />}>
                  <OpportunityGroup />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/opportunity/meetingAndCatering"
              element={
                <React.Suspense fallback={<Loader />}>
                  <OpportunityMeetingCatering />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/SetupAndConfiguration"
              element={
                <React.Suspense fallback={<Loader />}>
                  <SetupAndConfigurations />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/SetupAndConfigurations/createNewClient"
              element={
                <React.Suspense fallback={<Loader />}>
                  <CreateNewClients />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/SetupAndConfigurations/createNewClient/clientProperties"
              element={
                <React.Suspense fallback={<Loader />}>
                  <ClientProperties />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/SetupAndConfigurations/portfolios"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Portfolios />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/SetupAndConfigurations/hotels"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Hotels />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/SetupAndConfigurations/user"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Users />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/SetupAndConfigurations/userRole"
              element={
                <React.Suspense fallback={<Loader />}>
                  <UsersRole />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/SetupAndConfigurations/accountRules"
              element={
                <React.Suspense fallback={<Loader />}>
                  <AccountRules />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/SetupAndConfigurations/marketSegmentType"
              element={
                <React.Suspense fallback={<Loader />}>
                  <MarketSagmentTypes />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/SetupAndConfigurations/additionalSettings"
              element={
                <React.Suspense fallback={<Loader />}>
                  <AdditionalSettings />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/SetupAndConfigurations/companyprofile"
              element={
                <React.Suspense fallback={<Loader />}>
                  <CompanyProfile />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/SetupAndConfigurations/generalsetting"
              element={
                <React.Suspense fallback={<Loader />}>
                  <GeneralSettings />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/miscellaneous/uploadDocument"
              element={
                <React.Suspense fallback={<Loader />}>
                  <UploadDocument />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/miscellaneous/calendar"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Calendar />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/Reports"
              element={
                <React.Suspense fallback={<Loader />}>
                  <ReportMain />
                </React.Suspense>
              }
            ></Route>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
