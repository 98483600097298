import "@szhsin/react-menu/dist/index.css";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import TopLogoDark from "../Asset/Images/Header/main-logo-dark.svg";
import TopLogo from "../Asset/Images/Header/main-logo.svg";
import Offcanvas from "react-bootstrap/Offcanvas";
import MenuIcon from "../Asset/Images/Header/menu-icon.png";
import MenuTop from "../Component/TopMenu/MenuTop";

function DefaultHeader() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="header-top-new-block 123456789">
        <div className="main-header">
          <div className="logo">
            <Link to="/home">
              <img className="light-visible" src={TopLogo} alt="" />
              <img className="dark-visible" src={TopLogoDark} alt="" />
            </Link>
          </div>
          <div className="d-none d-lg-flex align-items-lg-center justify-content-lg-around w-100">
            <MenuTop />
          </div>
          <Button variant="primary d-lg-none ms-auto" onClick={handleShow}>
            <img className="menu-icon" src={MenuIcon} alt="" />
          </Button>
          <Offcanvas show={show} onHide={handleClose} responsive="lg" placement="end">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Menu</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <MenuTop />
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </div>
    </>
  );
}

export default DefaultHeader;
